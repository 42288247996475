body {
    text-rendering: optimizeLegibility !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: 400;
    font-family: var(--bodyFont);
}

@-moz-document url-prefix() {
    body {
        font-weight: lighter !important;
    }
}

a {
    text-decoration: none;
}

p {
    strong {
        font-weight: 700;
    }
}
