.theCrunchAction {
    margin: 100px auto 0;
    max-width: 960px;
    padding-bottom: 100px;
    text-align: center;
    width: calc(100% - 40px);

    h3 {
        color: var(--colorWhite);
        font: 700 1.8rem/1.4 var(--bodyFont);
        margin-top: 0;
    }
}
