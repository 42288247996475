.theLastCrunch {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    min-height: 240px;
    position: relative;

    @include min-screen(768px) {
        min-height: 400px;
    }

    &:before {
        background: url("../img/asset.sectionPost.sky.svg") no-repeat 0 0
            transparent;
        background-size: 100% auto;
        content: "";
        display: block;
        height: (208px/900px) * 100vw;
        left: -5%;
        position: absolute;
        top: -1px;
        width: 110%;
    }

    .theLastCrunchContent {
        margin: 0 auto 30px;
        position: relative;
        width: calc(100% - 60px);
        z-index: 1;

        p {
            color: var(--colorWhite);
            font: 400 1rem/1.4 var(--bodyFont);
            margin: 0 0 1rem;
            text-align: center;

            a {
                color: var(--colorWhite);
                text-decoration: underline;
                transition: color 300ms ease;

                &:hover {
                    color: var(--colorSky);
                }
            }
        }

        .theLastCrunchPages {
            display: flex;
            justify-content: center;
            margin-top: 2rem;

            a {
                color: var(--colorWhite);
                font: 700 0.8rem/1 var(--bodyFont);
                padding: 0 10px;
                transition: color 300ms ease;

                &:hover {
                    color: var(--colorSky);
                }
            }
        }
    }
}
