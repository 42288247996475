html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2.44em;
  margin: 0.67em 0;
  line-height: 1.4;
}

h2 {
  font-size: 1.95em;
  line-height: 1.4;
}

h3 {
  font-size: 1.56em;
  line-height: 1.4;
}

h4 {
  font-size: 1.25em;
  line-height: 1.5;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.8em;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 15px;
  font-size: 0.9375rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

blockquote,
q {
  quotes: "" "";
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

hr {
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

ul,
ol {
  margin: 0 0 1.5em 3em;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
}

figure {
  margin: 1em 0;
  /* Extra wide images within figure tags don't overflow the content area. */
}

table {
  margin: 0 0 1.5em;
  width: 100%;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#primary[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/

.alignleft,
.alignright {
  max-width: 636px !important; /* Let's work to make this !important unnecessary */
}

.alignleft img,
.alignright img,
.alignleft figcaption,
.alignright figcaption {
  max-width: 50%;
  width: 50%;
}

.alignleft figcaption {
  clear: left;
}

.alignright figcaption {
  clear: right;
}

.alignleft img,
.alignleft figcaption {
  float: left;
  margin-right: 1.5em;
}

.alignright img,
.alignright figcaption {
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget-area {
  width: 25%;
}

.widget {
  margin: 0 0 1.5em;
  /* Make sure select elements fit in widgets. */
}

.widget select {
  max-width: 100%;
}

/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
  display: block;
}

.updated:not(.published) {
  display: none;
}

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.3333333333%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.6666666667%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.2857142857%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.1111111111%;
}

.gallery-caption {
  display: block;
}

.entry-content > * {
  margin: 36px auto;
  max-width: 680px;
}

.entry-content > .alignwide {
  max-width: 1100px;
}

.entry-content > .alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.entry-content ul,
.entry-content ol {
  margin: 1.5em auto;
  max-width: 680px;
  list-style-position: outside;
}

.wp-block-video video {
  max-width: 680px;
}

.wp-block-image img {
  display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
  width: 100%;
}

.wp-block-image.alignfull img {
  width: 100vw;
}

.wp-block-gallery:not(.components-placeholder) {
  margin: 1.5em auto;
}

.wp-block-cover-text p {
  padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
  padding: 0 14px;
}

.wp-block-table {
  display: block;
  overflow-x: auto;
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}

.wp-block-table td,
.wp-block-table th {
  padding: 0.5em;
}

.entry-content li {
  margin-left: 2.5em;
  margin-bottom: 6px;
}

.entry-content ul ul,
.entry-content ol ol,
.entry-content ul ol,
.entry-content ol ul {
  margin: 0 auto;
}

.entry-content ul ul li,
.entry-content ol ol li,
.entry-content ul ol li,
.entry-content ol ul li {
  margin-left: 0;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wp-block-quote.is-large {
  margin: 0 auto 16px;
}

.wp-block-pullquote > p:first-child {
  margin-top: 0;
}

.wp-block-separator {
  margin: 3em auto;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .wp-block-cover-text p {
    padding: 1.5em 0;
  }

  .entry-content > * {
    padding-left: 0px;
    padding-right: 0px;
  }
}

/*--------------------------------------------------------------
# Block Color Palette Colors
--------------------------------------------------------------*/
.has-strong-blue-color {
  color: #0073aa;
}

.has-strong-blue-background-color {
  background-color: #0073aa;
}

.has-lighter-blue-color {
  color: #229fd8;
}

.has-lighter-blue-background-color {
  background-color: #229fd8;
}

.has-very-light-gray-color {
  color: #eee;
}

.has-very-light-gray-background-color {
  background-color: #eee;
}

.has-very-dark-gray-color {
  color: #444;
}

.has-very-dark-gray-background-color {
  background-color: #444;
}
