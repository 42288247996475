.letsTalkAboutTheCrunch {
    background-color: var(--colorAqua);
    position: relative;

    &:before {
        background: url("../img/asset.sectionPost.navy.svg") no-repeat 0 0
            transparent;
        background-size: 100% auto;
        content: "";
        display: block;
        height: (55px/900px) * 100vw;
        left: -5%;
        position: absolute;
        top: -1px;
        width: 110%;
    }

    .crunchyContent {
        margin: 0 auto;
        max-width: 1200px;
        // padding-top: (90px/900px) * 100vw;
        padding-top: 60px;
        position: relative;
        width: calc(100% - 40px);
        z-index: 1;

        @include min-screen(768px) {
            padding-top: 0;
        }

        &.hasCrunchyInterface {
            padding-bottom: 80px;

            @include min-screen(768px) {
                display: grid;
                grid-template-columns: 1fr 200px;
                grid-gap: 60px;
            }

            @include min-screen(1024px) {
                display: grid;
                grid-template-columns: 1fr 300px;
                grid-gap: 100px;
                padding-bottom: 0;
            }

            @include min-screen(1200px) {
                grid-gap: 180px;
            }
        }

        .crunchyContentText {
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 40px;

            @include min-screen(1024px) {
                margin-bottom: 0;
            }

            .crunchyAppStores {
                display: grid;
                grid-template-columns: 1fr 1.125fr;
                grid-gap: 20px;
                max-width: 350px;
                margin-top: 60px;

                a {
                    display: block;
                    transition: transform 200ms ease;

                    img {
                        display: block;
                        width: 100%;
                    }

                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }

        .theCrunchline {
            color: var(--colorBlue);
            font: 700 1.5rem/1.2 var(--bodyFont);
            margin: 0;

            @include min-screen(1024px) {
                font-size: 3rem;
                margin-top: 100px;
            }

            .theCrunchlineTextWithAPunch {
                color: var(--colorWhite);
                display: inline-block;
                font: 700 2rem/1.2 var(--bodyFont);
                margin-top: 10px;

                @include min-screen(768px) {
                    margin-top: 0;
                }

                @include min-screen(1024px) {
                    font-size: 3.5rem;
                }
            }

            .theCrunchlineAnimated {
                display: block;

                @include min-screen(768px) {
                    display: inline-block;
                }

                i {
                    background: var(--colorBlue);
                    border-radius: 6px;
                    font-style: normal;
                    padding: 0 5px;
                }
            }
        }

        h2 {
            color: var(--colorWhite);
            font-size: 1.5rem;
            margin-top: 2rem;
        }

        .crunchyContentInterface {
            figure {
                display: block;
                margin: 0 auto;
                max-width: 300px;

                @include min-screen(768px) {
                    width: 275px;
                }

                @include min-screen(1024px) {
                    width: auto;
                }

                img {
                    display: block;
                    max-width: 100%;
                }
            }
        }
    }
}
