.no-scroll {
	overflow: hidden;
}

.home .body-content {
	background: transparent;
	padding: 0;
}

.wp-block-file {
	overflow-wrap: break-word !important;
}
