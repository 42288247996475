@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(60px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeUp {
    opacity: 0;
    position: relative;
    transform: translateY(60px);

    &.in-viewport {
        animation: fadeUp 500ms ease forwards;
    }
}

.theCrunchyWrapper {
    margin: 0 auto;
    max-width: 960px;
    width: calc(100% - 40px);
}

.button {
    border-radius: 5px;
    background-color: var(--colorWhite);
    color: var(--colorSky);
    display: inline-block;
    font: 700 1.5rem/60px var(--bodyFont);
    height: 60px;
    padding: 0 10px;
    text-align: center;
    transition: transform 200ms ease;
    transform-origin: center center;
    width: 180px;

    &:hover {
        transform: scale(1.1);
    }
}
