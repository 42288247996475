.theSeriousCrunch {
    background: var(--colorSky);
    padding-top: (108px/900px) * 100vw;
    position: relative;

    &:before {
        background: url("../img/asset.sectionPost.navy.alt.svg") no-repeat 0 0
            transparent;
        background-size: 100% auto;
        content: "";
        display: block;
        height: (208px/900px) * 100vw;
        left: -5%;
        position: absolute;
        top: -1px;
        width: 110%;
    }

    h1,
    h2,
    h3,
    li,
    p,
    a {
        color: var(--colorWhite);
    }

    p {
        &:last-of-type {
            margin-bottom: 0;
        }

        a {
            font-weight: 700;
            text-decoration: underline;
            transition: color 300ms ease;

            &:hover {
                color: var(--colorBlue);
            }
        }
    }

    h1 {
        font-size: 2rem;

        @include min-screen(1024px) {
            font-size: 3rem;
        }
    }

    h2 {
        font-size: 1.8rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.2rem;
    }
}
