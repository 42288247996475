:root {
    --colorWhite: #ffffff;
    --colorBlue: #364f6b;

    --colorAqua: #3fc1c9;
    --colorSky: #62a8f0;

    --bodyFont: "Nunito", "Helvetica Neue", "Helvetica", -apple-system,
        system-ui, "Segoe UI", Roboto, Oxygen-Sans, "U buntu", Cantarell,
        sans-serif;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: inherit;
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0px 1000px #eeeff1 inset;
    transition: background-color 5000s ease-in-out 0s;
    color: var(--colorBlue);
}

* {
    box-sizing: border-box;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

html {
    font-size: 1em;
    line-height: 1.4;
}

body {
    background: var(--colorBlue);
}

::-moz-selection {
    background: rgba(#364f6b, 0.5);
    text-shadow: none;
}

::selection {
    background: rgba(#364f6b, 0.5);
    text-shadow: none;
}

body,
button,
input,
select,
optgroup,
textarea {
    color: #000000;
    font-size: 20px;
    line-height: 1.8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
}

dfn,
cite,
em,
i {
    font-style: italic;
}

blockquote {
    margin: 0 1.5em;
}

address {
    margin: 0 0 1.5em;
}

pre {
    background: #eee;
    font-family: "Courier 10 Pitch", Courier, monospace;
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1.6em;
}

code,
kbd,
tt,
var {
    font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
    font-size: 15px;
    font-size: 0.9375rem;
}

abbr,
acronym {
    border-bottom: 1px dotted #666;
    cursor: help;
}

mark,
ins {
    background: #fff9c0;
    text-decoration: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

audio,
canvas,
img,
video {
    vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

/* ==========================================================================
   Browse Happy prompt
   ========================================================================== */

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.theCrunchOfItAll {
    overflow-x: hidden;
}
