.thePreCrunch {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 200px;
    padding-bottom: 20px;

    .theCrunchBox {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    figure {
        height: 50px;
        margin: 0;
        width: 188px;
        a {
            outline: none;
            text-decoration: none;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .aCrunchyDescription {
        color: var(--colorWhite);
        font: 400 1.2rem/1 var(--bodyFont);
        text-align: center;
        width: 100%;
    }
}
