.crunchyAlternatives {
    margin: 60px auto 0;
    max-width: 800px;
    width: calc(100% - 40px);

    .crunchyFeature {
        padding-bottom: 40px;

        @include min-screen(768px) {
            display: grid;
            grid-gap: 60px;
            grid-template-columns: 300px 1fr;
            padding-bottom: 60px;
        }

        &:nth-child(even) {
            @include min-screen(768px) {
                .crunchyFeatureText {
                    grid-template-columns: 1fr 300px;
                    order: -1;
                }
            }
        }

        .crunchyFeatureImage {
            margin-bottom: 60px;

            @include min-screen(1024px) {
                margin-bottom: 0;
            }

            figure {
                margin: 0 auto;
                max-width: 200px;

                @include min-screen(1024px) {
                    min-width: 300px;
                }
            }
        }

        .crunchyFeatureText {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                color: var(--colorWhite);
                font: 400 1.125rem/1.4 var(--bodyFont);
                margin: 0 auto 1rem;
                max-width: 360px;

                @include min-screen(768px) {
                    font-size: 1.3rem;
                    margin: 0 0 1rem;
                }
            }
        }
    }
}
